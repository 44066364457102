import { AppError, IFeatureState, Status } from '../system/types';

export type IModels = {
  id: string;
  name: string;
  price: string;
  type: string;
  brochureLink: string;
  unitLogoUrl: string;
  seatCapacity: number;
  bannerTitle: string;
  bannerText: string;
  bannerImageUrl: string;
  imagePreviewUrl: string;
  colorSelection: ModelColorSelection[];
  exterior: ModelContent[];
  interior: ModelContent[];
  performance: ModelContent[];
  safety: ModelContent[];
  accessory: ModelContent[];
  variant: ModelVariant[];
};

export interface ModelColorSelection {
  colorName: string;
  colorPreviewUrl: string;
  availabilityLabel: string;
  vehicleImagePreviewUrl: string;
}

export interface ModelContent {
  header: string;
  content: string;
  imagePreviewUrl: string;
}

export interface ModelVariantPerformance {
  imageUrl: string;
  mainLabel: string;
  subLabel: string;
  unitLabel: string;
}

export interface VariantItems {
  label: string;
  value: string;
}

export interface ModelVariantSpecs {
  category: string;
  items: VariantItems[];
}

export interface ModelVariant {
  name: string;
  performance: ModelVariantPerformance[];
  summary: {
    content: string;
    price: string | number;
  };
  specs: ModelVariantSpecs[];
}

export interface TestDriveFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface IModelTestDrive extends TestDriveFields {
  model: string;
  date: Date;
}

export interface SelectedModel {
  selectedVariants: ModelVariant[];
  selectedModels: IModels[];
  selectedModelIndex: number;
}

export type SelectedModelListState = SelectedModel;

export type ModelListState = IFeatureState<IModels>;

export const SET_SELECTED_MODEL = 'models/selected/set';
export const SET_MODEL_STATUS = 'models/status/set';
export const SET_MODEL_ERROR = 'models/error/set';
export const LOAD_MODELS_DATA = 'models/data/load';

export interface SetSelectedModelAction {
  type: typeof SET_SELECTED_MODEL;
  payload: { selectedVariants: Array<ModelVariant> };
}

interface SetModelListStatusAction {
  type: typeof SET_MODEL_STATUS;
  payload: { status: Status };
}

interface SetModelListErrorAction {
  type: typeof SET_MODEL_ERROR;
  payload: { error: AppError };
}

interface SetModelListDataAction {
  type: typeof LOAD_MODELS_DATA;
  payload: { data: ModelListState['data'] };
}

export interface SetSelectedModelAction {
  type: typeof SET_SELECTED_MODEL;
  payload: { selectedVariants: ModelVariant[] };
}

export type ModelListAction =
  | SetModelListStatusAction
  | SetModelListErrorAction
  | SetModelListDataAction
  | SetSelectedModelAction;
